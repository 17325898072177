import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Autocomplete, TextField, Box, Checkbox } from '@mui/material'
import { useAllQuestionnairesQuery } from 'store/questionnaires/questionnaires.api'
import { useDebounce } from 'use-debounce'
import { RegionContext } from '../../context'

export default function KrowQuestionnaireAutocomplete({
  setValue,
  error,
  attribute,
  defaultValue,
  disabled,
  localities,
}) {
  const { organizationId } = useParams()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(defaultValue ? defaultValue : null)
  const [debounced] = useDebounce(search, 400)

  const { regionId } = useContext(RegionContext)

  const { data: { data: questionnaires } = { data: [] }, isLoading } = useAllQuestionnairesQuery({
    organizationId,
    search: debounced,
    localities,
    regionId,
  })

  return (
    <Autocomplete
      value={selected}
      disabled={disabled}
      sx={{ width: '100%' }}
      options={questionnaires}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false)
        setSearch('')
      }}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Questionnaire"
          variant={'outlined'}
          onChange={(event) => setSearch(event.target.value)}
          error={error?.type === 'error'}
          helperText={error?.message}
        />
      )}
      renderOption={(props, option) => {
        return (
          <Box
            {...props}
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontSize: theme.typography.body2.fontSize,
            })}
          >
            <Checkbox checked={selected?.id === option.id} />
            {option.title}
          </Box>
        )
      }}
      onChange={(_, selected) => {
        if (null === selected || 'undefined' === typeof selected) return
        setSelected(selected)

        setValue(attribute, selected?.id, selected)
      }}
    />
  )
}
