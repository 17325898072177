import { api } from '../store.api'
import { ListQueryParams, OrganizationScope } from 'store/store.types'
import { providesList } from 'store/store.utils'
import { KrowIdentity, KrowDocument, KrowResponse, KrowPartialResource } from 'store/industry'
import deserialize from 'util/deserialize'
import { decodeQuestionnaire } from './questionnaire.service'
import { DomainPatch } from 'routes/Builder/pages/StandardBuilder/StandardBuilder'
import { EntityTypes } from 'routes/Builder/pages/QuestionnaireList/QuestionnaireList'
import { IncludedLocation } from 'store/locations/locations.api'
import { IncludedRegion } from 'store/regions/regions.api'
import { IncludedPosition } from 'store/positions/position.api'
import { IncludedOpening } from 'store/openings/opening.api'

export interface Patch {
  id: string
  op: string
  path: string
  from: string
  value: any
  group_id: string
  group_type: string
}

export interface Answer<P = Patch> {
  kind: 'answer'
  id: string
  title: string
  description: string
  visible: boolean
  patches: P[]
  image_url?: string
}

export interface Redirection {
  kind: 'redirection'
  id: string
  title: string
  description: string
  url: string
  visible: boolean
}

export interface Prompt {
  kind: 'prompt'
  id: string
  title: string
  types: string[]
  visible: boolean
}

export type QuestionKinds =
  | 'multiple_choice_question'
  | 'multiple_select_question'
  | 'text_input_question'
  | 'file_upload_question'
  | 'soft_egress_question'
  | 'hard_egress_question'

export interface Question<P = Patch> {
  kind: QuestionKinds
  id: string
  title: string
  description: string
  visible: boolean
  answers?: Answer<P>[]
  redirections?: Redirection[]
  prompts?: Prompt[]
  filter_tag?: string
  image_url?: string
}
export interface QuestionnaireAttributes {
  title: string
  description: string
  completion_url: string
  questions: Question[]
  state: { name: string; action: string }
  transitions: { name: string; action: string }[]
  organization_id: string
}

export interface DecodedQuestionnaireAttributes {
  title: string
  description: string
  completion_url: string
  questions: Question<DomainPatch>[]
  state: { name: string; action: string }
  organization_id: string
}

export interface IncludedQuestionnaire extends Questionnaire {
  entity: IncludedLocation | IncludedRegion | IncludedPosition | IncludedOpening
}

export type DecodedQuestionnaire = KrowIdentity<'questionnaire'> & DecodedQuestionnaireAttributes
export type Questionnaire = KrowIdentity<'questionnaire'> & QuestionnaireAttributes
export type QuestionnairePartialResource = KrowPartialResource<QuestionnaireAttributes>
export type QuestionnaireDocument = KrowDocument<'questionnaire', QuestionnaireAttributes>
export type QuestionnaireResponse = KrowResponse<QuestionnaireDocument>
export type QuestionnaireCollectionResponse = KrowResponse<QuestionnaireDocument[]>
export type QuestionnaireResult = KrowResponse<IncludedQuestionnaire>
export type DecodedQuestionnaireResult = KrowResponse<DecodedQuestionnaire>
export type QuestionnaireCollectionResult = KrowResponse<IncludedQuestionnaire[]>

export const questionnaireApi = api.injectEndpoints({
  endpoints: (build) => ({
    allQuestionnaires: build.query<
      QuestionnaireCollectionResult,
      OrganizationScope & ListQueryParams & { localities?: string[]; regionId?: string }
    >({
      query(scope) {
        const { organizationId, regionId, localities, ...params } = scope

        const filters = localities?.length
          ? {
              filter: {
                entity_type: 'Location,Organization,Region',
                entity_id: [...localities, organizationId, regionId].filter(Boolean).join(','),
              },
            }
          : {}

        return {
          method: 'GET',
          url: `organizations/${organizationId}/questionnaires`,
          params: {
            included: ['entity'],
            ...filters,
            ...params,
          },
        }
      },
      transformResponse: (
        response: QuestionnaireCollectionResponse
      ): QuestionnaireCollectionResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => {
        return result ? providesList(result.data, 'Questionnaire') : []
      },
    }),
    createQuestionnaire: build.mutation<
      QuestionnaireResponse,
      Partial<QuestionnaireAttributes> & OrganizationScope
    >({
      query(data) {
        const { organizationId, ...attributes } = data
        return {
          method: 'POST',
          url: `organizations/${organizationId}/questionnaires`,
          body: {
            data: {
              type: 'questionnaire',
              attributes,
            },
          },
        }
      },
      invalidatesTags: [{ type: 'Questionnaire', id: 'LIST' }],
    }),
    getOneQuestionnaire: build.query<DecodedQuestionnaireResult, string>({
      query(id) {
        return {
          method: 'GET',
          url: `questionnaires/${id}`,
        }
      },
      transformResponse: (response: QuestionnaireResponse): DecodedQuestionnaireResult => {
        return {
          ...response,
          data: decodeQuestionnaire(deserialize(response)),
        }
      },
      providesTags: (response, error, arg) => [{ type: 'Questionnaire', id: arg }],
    }),
    getOneUndecodedQuestionnaire: build.query<DecodedQuestionnaireResult, string>({
      query(id) {
        return {
          method: 'GET',
          url: `questionnaires/${id}`,
        }
      },
      transformResponse: (response: QuestionnaireResponse): DecodedQuestionnaireResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (response, error, arg) => [{ type: 'Questionnaire', id: arg }],
    }),
    getApplicationQuestionnaire: build.query<QuestionnaireResult, string>({
      query(id) {
        return {
          method: 'GET',
          url: `questionnaires/${id}`,
        }
      },
      transformResponse: (response: QuestionnaireResponse): QuestionnaireResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (response, error, arg) => [{ type: 'Questionnaire', id: arg }],
    }),
    updateQuestionnaire: build.mutation<
      QuestionnaireResponse,
      Partial<QuestionnaireAttributes> & { id: string }
    >({
      query(data) {
        const { id, ...attributes } = data
        return {
          method: 'PATCH',
          url: `questionnaires/${id}`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Questionnaire', id }],
    }),
    deleteQuestionnaire: build.mutation<void, string>({
      query(id) {
        return {
          method: 'DELETE',
          url: `questionnaires/${id}`,
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'Questionnaire', id }],
    }),
    assignQuestionnaire: build.mutation<
      void,
      { entityType: EntityTypes; entityId: string; id: string }
    >({
      query(params) {
        const { entityType, entityId, id } = params
        return {
          method: 'PUT',
          url: `${entityType}/${entityId}/questionnaires/${id}`,
        }
      },
      invalidatesTags: ['Questionnaire'],
    }),
  }),

  overrideExisting: false,
})

export const {
  useAllQuestionnairesQuery,
  useGetOneQuestionnaireQuery,
  useGetOneUndecodedQuestionnaireQuery,
  useCreateQuestionnaireMutation,
  useUpdateQuestionnaireMutation,
  useDeleteQuestionnaireMutation,
  useAssignQuestionnaireMutation,
  useGetApplicationQuestionnaireQuery,
} = questionnaireApi
