import React from 'react'
import clsx from 'clsx'
import { Scope } from 'store/store.types'
import { withRouter, matchPath, generatePath, RouteComponentProps } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DomainIcon from '@mui/icons-material/Domain'
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg'
import { ReactComponent as MyForms } from 'assets/icons/my-forms.svg'
import { ReactComponent as Settings } from 'assets/icons/settings.svg'
import AssignmentIcon from '@mui/icons-material/Assignment'
import GroupIcon from '@mui/icons-material/Group'
import { SlidersTransition } from '../SlidersTransition'
import { useStyles } from './SideNavigation.styles'
import { usePrevious } from 'shared/hooks/usePrevious'
import SideNavigationHeader from './SideNavigationHeader'
import { useParams } from 'react-router-dom'
import {
  OrganizationConfiguration,
  useGetOneOrganizationQuery,
} from 'store/organizations/organizations.api'
import { useGetMeQuery } from 'store/me/me.api'

import {
  Dashboard,
  ShareLocation,
  ContactPage,
  ImportExport,
  Work,
  QueryStats,
  NearMeOutlined,
} from '@mui/icons-material'

const orgPath = `/organizations/:organizationId`
const regionPath = `/organizations/:organizationId/regions/:regionId`
const localityPath = `/organizations/:organizationId/localities/:localityId`

const NOOP = () => true

const locationLinks = [
  { to: `${localityPath}/dashboard`, Icon: Dashboard, text: 'Dashboard', authorize: NOOP },
  {
    to: `${localityPath}/openings`,
    Icon: AssignmentIcon,
    text: 'Openings',
    authorize: NOOP,
  },
  {
    to: `${localityPath}/candidates`,
    Icon: ContactPage,
    text: 'Candidates',
    exact: false,
    authorize: NOOP,
  },
  {
    to: `${localityPath}/exports`,
    Icon: ImportExport,
    text: 'Exports',
    authorize: (actor: any, configuration: OrganizationConfiguration, organization: any) => {
      return (
        ['active_administrators', 'account_managers'].some((type) => type === actor.type) ||
        (actor.entity_type === 'Location' && configuration?.local_export?.toggle?.enabled)
      )
    },
  },
  {
    to: `${localityPath}/interviews`,
    Icon: Calendar,
    text: 'Calendar',
    authorize: (_: any, configuration: OrganizationConfiguration) =>
      null !== configuration.itinerary,
  },
]

const orgLinks = [
  {
    to: `${orgPath}/media`,
    Icon: QueryStats,
    text: 'Media Stats',
    authorize: (_: any, configuration: OrganizationConfiguration) =>
      configuration?.appcast_analytics?.id ? true : false,
  },
  { to: `${orgPath}/dashboard`, Icon: Dashboard, text: 'Dashboard', authorize: NOOP },
  {
    to: `${orgPath}/landing-pages`,
    Icon: NearMeOutlined,
    text: 'Landing Pages',
    authorize: (actor: any) =>
      ['active_administrators', 'account_managers'].some((type) => type === actor.type),
  },
  { to: `${orgPath}/localities`, Icon: ShareLocation, text: 'Localities', authorize: NOOP },
  {
    to: `${orgPath}/funnel`,
    Icon: Work,
    text: 'Positions',
    authorize: (actor: any) =>  actor.type !== 'organization_members',
  },
  { to: `${orgPath}/users`, Icon: GroupIcon, text: 'Users', authorize: NOOP },
  {
    to: `${orgPath}/questionnaires`,
    Icon: MyForms,
    text: 'Questionnaires',
    exact: false,
    authorize: (actor: any) =>
      ['active_administrators', 'account_managers'].some((type) => type === actor.type),
  },
  {
    to: `${orgPath}/candidates`,
    Icon: ContactPage,
    text: 'Candidates',
    exact: false,
    authorize: NOOP,
  },
  {
    to: `${orgPath}/exports`,
    Icon: ImportExport,
    text: 'Exports',
    authorize: (actor: any, _: any, organization: any) => {
      return (
        ['active_administrators', 'account_managers'].some((type) => type === actor.type) ||
        (actor.entity_type === 'Organization' && actor.entity_id === organization?.data?.id)
      )
    },
  },
  {
    to: `${orgPath}/org_settings`,
    Icon: Settings,
    text: 'Settings',
    exact: false,
    authorize: (actor: any, configuration: OrganizationConfiguration, organization: any) => {
      return ['active_administrators', 'account_managers'].some((type) => type === actor.type)
    },
  },
]

const regionLinks = [
  { to: `${regionPath}/dashboard`, Icon: Dashboard, text: 'Dashboard', authorize: NOOP },
  {
    to: `${regionPath}/locations`,
    Icon: ShareLocation,
    text: 'Locations',
    authorize: (_: any, configuration: OrganizationConfiguration) =>
      'Location' === configuration.determinant?.strategy,
  },
  { to: `${regionPath}/users`, Icon: GroupIcon, text: 'Users', authorize: NOOP },
  {
    to: `${regionPath}/openings`,
    Icon: AssignmentIcon,
    text: 'Openings',
    exact: false,
    authorize: (_: any, configuration: OrganizationConfiguration) =>
      'Region' === configuration.determinant?.strategy,
  },
  {
    to: `${regionPath}/exports`,
    Icon: ImportExport,
    text: 'Exports',
    authorize: (actor: any, configuration: OrganizationConfiguration, organization: any) => {
      return (
        ['active_administrators', 'account_managers'].some((type) => type === actor.type) ||
        (actor.entity_type === 'Region' && configuration?.local_export?.toggle?.enabled)
      )
    },
  },
  {
    to: `${regionPath}/candidates`,
    Icon: ContactPage,
    text: 'Candidates',
    exact: false,
    authorize: NOOP,
  },
  {
    to: `${regionPath}/interviews`,
    Icon: Calendar,
    text: 'Calendar',
    exact: false,
    authorize: (_: any, configuration: OrganizationConfiguration) =>
      null !== configuration.itinerary && 'Region' === configuration.determinant?.strategy,
  },
]

const superLinks = [
  { to: '/organizations', Icon: DomainIcon, text: 'Organizations', authorize: NOOP },
  {
    to: '/account-managers',
    Icon: GroupIcon,
    text: 'Account Managers',
    authorize: (actor: any) => 'active_administrators' === actor.type,
  },
]

interface SideNavigationProps extends RouteComponentProps<Scope> {
  open: boolean
  setOpen: (open: boolean) => void
  children?: React.ReactNode | React.ReactNode[]
}

const drawerWidth = 300

const activePanel = (pathname: string) => {
  if (superLinks.some(({ to }) => matchPath(pathname, { path: to, exact: true }))) {
    return 'super'
  }

  if (locationLinks.some(({ to, exact = true }) => matchPath(pathname, { path: to, exact }))) {
    return 'location'
  }

  if (regionLinks.some(({ to, exact = true }) => matchPath(pathname, { path: to, exact }))) {
    return 'region'
  }

  if (orgLinks.some(({ to, exact = true }) => matchPath(pathname, { path: to, exact }))) {
    return 'organization'
  }

  return 'location'
}

const panels = ['super', 'organization', 'region', 'location']

const path = (path: string, context: Record<string, string>) => {
  try {
    return generatePath(path, context)
  } catch {
    return ''
  }
}

const panelLinks = {
  super: superLinks,
  organization: orgLinks,
  region: regionLinks,
  location: locationLinks,
}

function SideNavigation(props: SideNavigationProps) {
  const { organizationId } = useParams<Scope>()
  const { data: organization } = useGetOneOrganizationQuery(organizationId, {
    skip: 'undefined' === typeof organizationId,
  })
  const { data: me } = useGetMeQuery()

  const configuration = organization?.data.configuration || {}
  const actor = me?.data || {}

  const classes = useStyles()

  const { open, setOpen } = props
  const theme = useTheme()

  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const [expandedSidebar, setExpandedSidebar] = React.useState<boolean>(false)

  const handleExpandSideBar = () => {
    if (matches) {
      setExpandedSidebar(true)
    }
  }

  const handleShrinkSideBar = () => {
    if (matches) {
      setExpandedSidebar(false)
    }
  }

  const {
    location: { pathname },
    match: { params },
  } = props
  const [panel, setPanel] = React.useState<ReturnType<typeof activePanel>>(activePanel(pathname))
  const previousPanel = usePrevious<string>(panel)
  const scope = Object.assign(params)

  React.useEffect(() => {
    setPanel(activePanel(pathname))
  }, [pathname])

  const slideNodeRef = React.useMemo(() => {
    return React.createRef<HTMLDivElement>()
  }, [panel]) // eslint-disable-line

  return (
    <Drawer
      variant={matches ? 'permanent' : 'temporary'}
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.drawer }}
      className={clsx(classes.root, { [classes.expandedSidebar]: expandedSidebar })}
      onMouseEnter={handleExpandSideBar}
      onMouseLeave={handleShrinkSideBar}
      ModalProps={{ keepMounted: true }}
      sx={{ width: drawerWidth }}
    >
      <SlidersTransition
        className={classes.slider}
        transKey={panel}
        nodeRef={slideNodeRef}
        slideDirection={
          panels.indexOf(panel) > panels.indexOf(previousPanel || '') ? 'left' : 'right'
        }
      >
        <Box ref={slideNodeRef} overflow="hidden">
          <SideNavigationHeader />
          <Box>
            {Object.entries(panelLinks).map(
              ([panelType, links]) =>
                panel === panelType && (
                  <List key={panelType}>
                    {links
                      .filter(({ authorize }) => authorize(actor, configuration, organization))
                      .map(({ Icon, to, text }) => {
                        return (
                          <NavLink
                            className={classes.link}
                            data-cy={to}
                            activeClassName={classes.activeLink}
                            to={path(to, { ...scope })}
                            key={text}
                          >
                            <ListItem
                              tabIndex={-1}
                              disableRipple
                              button
                              className={classes.iconWrapper}
                            >
                              <ListItemIcon>
                                <Icon className={classes.icon} />
                              </ListItemIcon>
                              <ListItemText className={classes.linkText} primary={text} />
                            </ListItem>
                          </NavLink>
                        )
                      })}
                  </List>
                )
            )}
          </Box>
        </Box>
      </SlidersTransition>
    </Drawer>
  )
}

export default withRouter(SideNavigation)
