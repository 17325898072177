import { skipToken } from '@reduxjs/toolkit/query'
import { createContext, FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetOneLocationQuery } from '../../store/locations/locations.api'
import { Scope } from '../../store/store.types'

interface RegionContextType {
  regionId: string | null
  isLoading: boolean
}

export const RegionContext = createContext<RegionContextType>({
  regionId: null,
  isLoading: false,
})

export const RegionProvider: FC = ({ children }) => {
  const { regionId, localityId } = useParams<Scope>()

  const { data: locality, isLoading } = useGetOneLocationQuery(localityId ? localityId : skipToken)

  const state = useMemo<RegionContextType>(
    () => ({
      regionId: regionId ?? locality?.included?.find((item) => item.type === 'regions')?.id ?? null,
      isLoading: isLoading,
    }),
    [regionId, isLoading, locality]
  )

  return <RegionContext.Provider value={state}>{children}</RegionContext.Provider>
}
