import { api } from '../store.api'
import { providesList } from 'store/store.utils'
import { ListQueryParams } from 'store/store.types'
import {
  KrowIdentity,
  KrowDocument,
  KrowResponse,
  KrowPartialResource,
  KrowResource,
  KrowCollection,
  KrowQuery,
} from 'store/industry'
import deserialize from 'util/deserialize'
import { IncludedRegion } from 'store/regions/regions.api'
import { IncludedOrganization } from 'store/organizations/organizations.api'
import { Country, Area } from 'store/country/country.api'

export type LocationAttributes = {
  address: string
  unit: string
  city: string
  state: string
  name: string
  postal_code: string
  time_zone: string
  lat: number
  lng: number
  coords: {
    latitude: number | null
    longitude: number | null
  }
  area_id: string
  phone_number: string
  parent_id: string
  external_id: string
  organization_id: string
  created_at: string
  updated_at: string
  rolling_daily_apply_count: number | null
  rolling_monthly_apply_count: number | null
  rolling_daily_hire_count: number | null
  rolling_monthly_hire_count: number | null
  average_days_to_decision: number | null
}

export type TLocationDocument = KrowDocument<'location', LocationAttributes>
export type TLocationResource = KrowResource<'location', LocationAttributes>
export type TLocationCollection = KrowCollection<'location', LocationAttributes>

export interface IncludedLocation extends Location {
  country: Country
  area: Area
  region: IncludedRegion
  organization: IncludedOrganization
}
export type Location = KrowIdentity<'location'> & LocationAttributes
export type LocationPartialResource = KrowPartialResource<LocationAttributes>
export type LocationDocument = KrowDocument<'location', LocationAttributes>
export type LocationResponse = KrowResponse<LocationDocument>
export type LocationCollectionResponse = KrowResponse<LocationDocument[]>
export type LocationResult = KrowResponse<IncludedLocation, any>
export type LocationCollectionResult = KrowResponse<IncludedLocation[]>

interface LocationsQuery {
  entityType: 'regions' | 'organizations'
  entityId: string
}

export const locationApi = api.injectEndpoints({
  endpoints: (build) => ({
    listLocations: build.query<
      TLocationCollection,
      KrowQuery<LocationAttributes> & { entityType: 'organizations' | 'regions'; entityId: string }
    >({
      query({ entityType, entityId, search, sort, page = { number: 1, size: 25 }, filter }) {
        return {
          method: 'GET',
          url: `${entityType}/${entityId}/locations`,
          params: {
            search,
            sort,
            page,
            filter,
            included: ['organization', 'region'],
          },
        }
      },
      transformResponse: (response: any): any => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) =>
        result?.data
          ? providesList(
              result?.data.map(({ id }) => ({ id: id as string })),
              'Location'
            )
          : [],
    }),
    allLocations: build.query<LocationCollectionResult, ListQueryParams & LocationsQuery>({
      query({ entityType, entityId, search, sort, page = { number: 1, size: 25 }, filter }) {
        return {
          method: 'GET',
          url: `${entityType}/${entityId}/locations`,
          params: {
            included: ['organization', 'area', 'country'],
            search,
            filter,
            sort,
            page,
          },
        }
      },
      transformResponse: (response: LocationCollectionResponse): LocationCollectionResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => (result?.data ? providesList(result.data, 'Location') : []),
    }),
    createLocation: build.mutation<
      LocationResponse,
      Partial<LocationAttributes> & LocationsQuery & { batchToken?: string }
    >({
      query({ entityType, entityId, batchToken, ...attributes }) {
        const headers = batchToken ? { 'X-Batch-Token': batchToken } : undefined

        return {
          method: 'POST',
          url: `${entityType}/${entityId}/locations`,
          body: {
            data: { type: 'location', attributes },
          },
          headers: headers,
        }
      },
      invalidatesTags: [{ type: 'Location', id: 'LIST' }],
    }),
    getOneLocation: build.query<LocationResult, string>({
      query(id) {
        return {
          method: 'GET',
          url: `/locations/${id}`,
          params: {
            included: ['region'],
          },
        }
      },
      transformResponse: (response: LocationResponse): LocationResult => ({
        ...response,
        data: deserialize(response),
      }),
      providesTags: (result, error, id) => [{ type: 'Location', id }],
    }),
    updateLocation: build.mutation<LocationResult, Partial<LocationAttributes> & { id: string }>({
      query(data) {
        const { id, ...attributes } = data
        return {
          method: 'PATCH',
          url: `locations/${id}`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      transformResponse: (response: LocationResponse): LocationResult => ({
        ...response,
        data: deserialize(response),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Location', id }],
    }),
    deleteLocation: build.mutation<Location, string>({
      query(id) {
        return {
          method: 'DELETE',
          url: `locations/${id}`,
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'Location', id }],
    }),
  }),

  overrideExisting: true,
})

export const {
  useListLocationsQuery,
  useCreateLocationMutation,
  useAllLocationsQuery,
  useGetOneLocationQuery,
  useLazyAllLocationsQuery,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
} = locationApi
